import { Component, ContentChild, input, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabViewModule } from 'primeng/tabview';


/**
 * This component represents a single tab panel within the `epg-tab-view` component. 
 * It works in conjunction with `epg-tab-view` to create a tabbed interface.
 * 
 * @example
 * ```html
 * <epg-tab-view>
 *   <epg-tab-panel header="Tab 1">
 *     <ng-template> 
 *       Content of Tab 1 
 *     </ng-template>
 *   </epg-tab-panel>
 * </epg-tab-view>
 * ```
 */

@Component({
  selector: 'epg-tab-panel',
  standalone: true,
  imports: [
    CommonModule, 
    TabViewModule,
  ],
  template: `
    <ng-template>
      <ng-content />
    </ng-template>`,
  styleUrl: './epg-tab-panel.component.scss',
})
export class EpgTabPanelComponent {
  header = input<string>('');
  @ContentChild(TemplateRef) templateRef!: TemplateRef<unknown>;

  tabs: { header: string, content: unknown }[] = [];
}
