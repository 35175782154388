import { Component, OnInit, OnDestroy, output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {debounceTime, Subject, takeUntil} from "rxjs";

@Component({
  selector: 'cdh-ui-search-filter',
  standalone: true,
  templateUrl: './cdh-search-filter.component.html',
  styleUrl: './cdh-search-filter.component.scss',
  imports: [ReactiveFormsModule],
})
export class CdhSearchFilterComponent implements OnInit, OnDestroy {
  searchChange = output<string>();

  ngOnInit() {
    this.searchInput.valueChanges
      .pipe(debounceTime(300), takeUntil(this.onDestroy$))
      .subscribe((searchTerm) => {
        if (searchTerm != null) {
          this.searchChange.emit(searchTerm);
        }
      });
  }
  searchInput = new FormControl<string>('');

  private onDestroy$: Subject<boolean> = new Subject();

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }
}
