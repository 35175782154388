import {Component, computed, input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiCardComponent} from "@epg-apps/cdh-ui-card";
import {CardFieldComponent} from "@epg-apps/cdh-ui-card-field";
import {TranslateModule} from "@ngx-translate/core";
import {ProcessedFeedbacks} from "@epg-apps/cdh-data-access-environment";

@Component({
  selector: 'cdh-ui-processed-feedback-errors',
  standalone: true,
  imports: [CommonModule, UiCardComponent, CardFieldComponent, TranslateModule],
  templateUrl: './processed-feedback-card-errors.component.html',
  styleUrl: './processed-feedback-card-errors.component.scss',
})
export class ProcessedFeedbackCardErrors {
  processedFeedback = input.required<ProcessedFeedbacks>();
  errorMessagePresent = computed(() => !!this.processedFeedback().error)
}
