<div
  ellipsis
  class="item-line flex-col align-items-center tooltip"
  [attr.id]="'sidebar-item-line-' + 'item.getTitle()'"
  (click)="selectItem()"
  [ngClass]="{
        active: item().id === selectedItem()?.id
      }">
  <div
    class="w-full item-name flex align-items-center justify-content-between"
    [attr.id]="'sidebar-contractName-' + 'item.getTitle()'">
    <div id="title" class="overflow">{{ item().title }}</div>
    <div class="flex column-gap-1">
    </div>
  </div>
  <div id="description" class="overflow description">
    {{ item().description }}
  </div>
</div>
<div class="line-divider"></div>
