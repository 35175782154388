import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UxlibCoreModule } from '@partena/uxlib-core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cdh-ui-card',
  standalone: true,
  imports: [CommonModule, UxlibCoreModule, TranslateModule],
  templateUrl: './ui-card.component.html',
  styleUrl: './ui-card.component.scss',
})
export class UiCardComponent {
  title = input<string>('');
  canExpand = input<boolean>(true);
  isExpanded = input<boolean>(true);
}
