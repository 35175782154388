import { Component, computed, inject, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UxlibCoreModule } from '@partena/uxlib-core';
import { CardFieldComponent } from '@epg-apps/cdh-ui-card-field';
import { CustomerDetail, CustomerService } from '@epg-apps/cdh-customers-data-access';
import { UiCardComponent } from '@epg-apps/cdh-ui-card';

@Component({
  selector: 'cdh-ui-customer-card-profile',
  standalone: true,
  imports: [CommonModule, UxlibCoreModule, CardFieldComponent, UiCardComponent],
  templateUrl: './customer-card-profile.component.html',
  styleUrl: './customer-card-profile.component.scss',
})
export class CustomerCardProfileComponent {
  private static readonly  leadingZeroesRegex = RegExp('^0+(?!$)')
  customerDetail: Signal<CustomerDetail> =
    inject(CustomerService).customerDetail;

  countryCode: Signal<string> = computed(() => {
    //todo add translation when available
    return this.customerDetail().country.replace(CustomerCardProfileComponent.leadingZeroesRegex, '')
  })
}
