import {Component, computed, input} from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusChipType, UxlibCoreModule } from '@partena/uxlib-core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cdh-ui-card-field',
  standalone: true,
  imports: [CommonModule, UxlibCoreModule, TranslateModule],
  templateUrl: './card-field.component.html',
  styleUrl: './card-field.component.scss',
})
export class CardFieldComponent {
  label = input<string>('');
  value = input<number | string | string[]>();
  chipText = input<string>('');
  chipType = input<StatusChipType | undefined>();
  chipHasError = input<boolean>(false);
  chipHasWarning = input<boolean>(false)
  drawLine = input<boolean>(true)
  boldLabel = input<boolean>(true)

  valueType = computed<inputType>(() => {
    switch (typeof  this.value()) {
      case "string": return inputType.string
      case "number": return inputType.number
      default: return inputType.stringArray
    }
  })

  stringValue = computed<string>(() => this.valueType() === inputType.string ? this.value() as string : '')
  stringArrayValue = computed<string[]>(() => this.valueType() === inputType.stringArray  ? this.value() as string[] : [])
  numberValue = computed<number>(() => this.valueType() === inputType.number  ? this.value() as number : 0)
  showChip = computed<boolean>(() => !!this.chipText() && !!this.chipType() && !this.value());
  protected readonly inputType = inputType;
}

enum inputType {
  number,
  stringArray,
  string
}
