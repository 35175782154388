import { Component, input, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonDirective } from 'primeng/button';

@Component({
  selector: 'cdh-button-ui',
  standalone: true,
  imports: [CommonModule, ButtonDirective],
  templateUrl: './cdh-button-ui.component.html',
  styleUrl: './cdh-button-ui.component.scss',
})
export class CdhButtonUiComponent {
  name = input<string>('');
  label = input<string>('');
  disabled = input<boolean>(false);
  icon = input<string>('');
  loading = input<boolean>(false);
  click = output<void>();
}
