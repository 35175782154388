import {Component, computed, input} from '@angular/core';
import {CommonModule, DatePipe, DecimalPipe, formatDate} from '@angular/common';
import {ProcessedFeedbacks} from "@epg-apps/cdh-data-access-environment";
import {TranslateModule} from "@ngx-translate/core";
import {UxlibCoreModule} from "@partena/uxlib-core";
import {ProcessedFeedbacksFormatted} from "@epg-apps/cdh-data-access-environment";
import {ProcessedFeedbackCardFilesTableComponent} from "../ui-processed-feedback-card-files-table/processed-feedback-card-files-table.component";
import {ProcessedFeedbackCardWarnings} from "../ui-processed-feedback-card-warnings/processed-feedback-card-warnings.component";
import {ProcessedFeedbackCardErrors} from "../ui-processed-feedback-card-errors/processed-feedback-card-errors.component";

@Component({
  selector: 'cdh-ui-processed-feedback-general',
  standalone: true,
  imports: [CommonModule, TranslateModule, UxlibCoreModule, DatePipe, DecimalPipe, ProcessedFeedbackCardFilesTableComponent, ProcessedFeedbackCardWarnings, ProcessedFeedbackCardErrors],
  templateUrl: './ui-processed-feedback-general.component.html',
  styleUrl: './ui-processed-feedback-general.component.scss',
})
export class UiProcessedFeedbackGeneralComponent {
  protected readonly START_TIME = 'customers.tabs.environment.content.processedFeedback.tabTitle.updateOf'
  protected readonly PROCESSED_TIME = 'customers.tabs.environment.content.processedFeedback.tabTitle.processingTime';
  protected readonly SECONDS = 'customers.tabs.environment.content.processedFeedback.tabTitle.seconds';
  processedFeedbacks = input.required<ProcessedFeedbacks[]>()
  protected sortedProcessedFeedbacks = computed<ProcessedFeedbacksFormatted[]>(() => this.processedFeedbacks()
  .sort((a, b): number => b.startTime - a.startTime)
  .map(value => ({
    ...value,
    formattedStartTime: formatDate(value.startTime * 1000, 'dd/MM/yyyy HH:mm:ss', 'en-US'),
    formattedProcessingTime: value.processingTime.toLocaleString()
  })))

}
