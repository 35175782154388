import { Component, input, output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BaseEntity } from '@epg-apps/cdh-model-base';
import { NgClass } from '@angular/common';
//todo Ryan shared core modules
import { UxlibCoreModule } from '@partena/uxlib-core';

@Component({
  selector: 'cdh-ui-search-item',
  standalone: true,
  templateUrl: './search-item.component.html',
  styleUrl: './search-item.component.scss',
  imports: [NgClass, UxlibCoreModule, RouterModule],
})
export class CdhSearchItemComponent<T extends BaseEntity> {
  itemSelected = output<T>();
  item = input.required<T>();
  selectedItem = input<T>();

  selectItem(): void {
    this.itemSelected.emit(this.item());
    return;
  }
}
