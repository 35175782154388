import {Component, computed, input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiCardComponent} from "@epg-apps/cdh-ui-card";
import {CardFieldComponent} from "@epg-apps/cdh-ui-card-field";
import {TranslateModule} from "@ngx-translate/core";
import {ProcessedFeedbacks} from "@epg-apps/cdh-data-access-environment";

@Component({
  selector: 'cdh-ui-processed-feedback-warnings',
  standalone: true,
  imports: [CommonModule, UiCardComponent, CardFieldComponent, TranslateModule],
  templateUrl: './processed-feedback-card-warnings.component.html',
  styleUrl: './processed-feedback-card-warnings.component.scss',
})
export class ProcessedFeedbackCardWarnings {
  private readonly NO_PRESENT: string = "customers.tabs.environment.content.processedFeedback.warningsCard.noPresent";
  private readonly ONE_PRESENT: string = "customers.tabs.environment.content.processedFeedback.warningsCard.onePresent";
  private readonly MANY_PRESENT: string = "customers.tabs.environment.content.processedFeedback.warningsCard.manyPresent";

  processedFeedback = input.required<ProcessedFeedbacks>();
  warningPresent = computed(() => !!this.processedFeedback().warnings && this.processedFeedback().warnings!.length > 0)

  title = computed<string>(() => {
    if (!this.processedFeedback().warnings || this.processedFeedback().warnings!.length === 0) {
      return this.NO_PRESENT;
    } else if(this.processedFeedback().warnings!.length === 1) {
      return this.ONE_PRESENT;
    } else {
      return this.MANY_PRESENT;
    }
  })
}
