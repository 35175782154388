<cdh-ui-card
  title="customers.tabs.general.content.commercialStatusCard.title">
  <div class="grid">
    <ng-container *ngFor="let product of customerDetail().customerProductDTOs; let last = last">
      <cdh-ui-card-field
        [label]="product.product"
        [value]="product.status"
        [boldLabel]="true"
        [drawLine]="!last"
      ></cdh-ui-card-field>
    </ng-container>
  </div>
</cdh-ui-card>
