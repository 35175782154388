<ng-container>
  <div class="flex flex-wrap">
    <div class="col-12 lg:col-6 xl:col-4">
      <cdh-ui-customer-card-profile/>
    </div>
    <div class="col-12 lg:col-6 xl:col-4">
      <cdh-ui-customer-card-commercial-status/>
    </div>
  </div>
</ng-container>
