import { Component, input, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '@epg-apps/cdh-environments';
import {
  EpgNavbarUiComponent,
  NavbarUIConfig,
  NavMenuLinkItem,
} from '@epg-apps/epg-ui-navbar';

@Component({
  selector: 'cdh-header-ui',
  standalone: true,
  imports: [CommonModule, EpgNavbarUiComponent],
  templateUrl: './cdh-header-ui.component.html',
  styleUrl: './cdh-header-ui.component.scss',
})
export class CdhHeaderUiComponent {
  // Inputs
  primaryMenuConfig = input.required<NavbarUIConfig>();
  secondaryMenuConfig = input.required<NavMenuLinkItem[]>();

  changeLanguage = output<string>();

  onChangeLanguage(language: string) {
    this.changeLanguage.emit(language);
  }

  onLogout() {
    window.location.href = environment.logoutUrl;
  }
}
