<cdh-ui-card [title]="'customers.tabs.environment.content.connectionStatusCard.title'">
  <div class="grid">
    <ng-container *ngFor="let connection of connectionResults(); let last = last">
      <ng-container *ngIf="connection.success; else failure">
        <cdh-ui-card-field
          [label]="connection.name"
          [chipText]="'customers.tabs.environment.content.connectionStatusCard.success'"
          [chipType]="'success'"
          [drawLine]="!last"
        />
      </ng-container>
      <ng-template #failure>
        <cdh-ui-card-field
          [label]="connection.name"
          [chipText]="'customers.tabs.environment.content.connectionStatusCard.failure'"
          [chipType]="'error'"
          [drawLine]="false"
        />
        <cdh-ui-card-field
          [label]="'customers.tabs.environment.content.connectionStatusCard.url'"
          [value]="connection.url"
          [drawLine]="false"
        ></cdh-ui-card-field>
        <cdh-ui-card-field
          [label]="'customers.tabs.environment.content.connectionStatusCard.message'"
          [value]="connection.message"
          [drawLine]="!last"
        />
      </ng-template>
    </ng-container>
  </div>
</cdh-ui-card>
