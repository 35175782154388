export interface UserProfile {
  name: string;
  email: string;
  groups: string[];
  roles: Role[];
}

export enum Role {
  IAM_ADMIN = 'IAM_ADMIN',
  IAM_READER = 'IAM_READER',
  IAM_WRITER = 'IAM_WRITER',
  IAM_AUDITOR = 'IAM_AUDITOR',
  CDH_ADMIN = 'CDH_ADMIN',
  CDH_READER = 'CDH_READER',
  EPGDRS_ADMIN = 'EPGDRS_ADMIN',
  EPGDRS_READER = 'EPGDRS_READER',
  EPGDRS_WRITER = 'EPGDRS_WRITER',
}
