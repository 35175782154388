import {Component, computed, input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiCardComponent} from "@epg-apps/cdh-ui-card";
import {CardFieldComponent} from "@epg-apps/cdh-ui-card-field";
import {DetailedEnvironment} from "@epg-apps/cdh-data-access-environment";

@Component({
  selector: 'cdh-ui-environment-card-last-update',
  standalone: true,
  imports: [CommonModule, UiCardComponent, CardFieldComponent],
  templateUrl: './environment-card-last-update.component.html',
  styleUrl: './environment-card-last-update.component.scss',
})
export class EnvironmentCardLastUpdateComponent {
  environment = input.required<DetailedEnvironment>();
  lastUpdatedMilliseconds = computed<number>(() => this.environment().lastUpdated * 1000)
}
