import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EpgNavbarUiComponent, NavMenuLinkItem } from '@epg-apps/epg-ui-navbar';
import {
  CDHPrimaryMenuConfig,
  CDHSecondaryMenuConfig,
} from '@epg-apps/cdh-config-header';
import { CdhHeaderUiComponent } from '@epg-apps/cdh-ui-header';
import { EpgTranslationsService, TranslationLocal } from '@epg-apps/util-translations';

@Component({
  selector: 'cdh-feature-header',
  standalone: true,
  imports: [CommonModule, EpgNavbarUiComponent, CdhHeaderUiComponent],
  templateUrl: './cdh-feature-header.component.html',
  styleUrl: './cdh-feature-header.component.scss',
})
export class CdhFeatureHeaderComponent {
  translationService = inject(EpgTranslationsService);

  public primaryMenuConfig = CDHPrimaryMenuConfig;
  public cdhSecondaryMenuConfig: NavMenuLinkItem[] = CDHSecondaryMenuConfig;

  

  onChangeLanguage(language: string) {
    switch(language) {
      case 'nl':
        this.translationService.use(TranslationLocal.NL);
        break;
      case 'fr':
        this.translationService.use(TranslationLocal.FR);
        break;
      default:
        this.translationService.use(TranslationLocal.EN);
    }
  }
}
