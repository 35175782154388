<cdh-ui-card
  [canExpand]="warningPresent()"
  [isExpanded]="warningPresent()"
  [title]="title()">
  <div class="grid">
    <ng-container *ngFor="let warning of processedFeedback().warnings; let last = last; let i = index">
      <cdh-ui-card-field
        [attr.data-token]="'processFeedbackWarningCode-' + i"
        [label]="warning.code"
        [drawLine]="false"
      />
      <cdh-ui-card-field
        [attr.data-token]="'processFeedbackWarningMessage-' + i"
        [label]="warning.message"
        [boldLabel]="false"
        [drawLine]="!last"
      />
    </ng-container>
  </div>
</cdh-ui-card>
