{
  "uxlib": {
    "general": {
      "undo": "Undo",
      "save": "Save",
      "close": "Close",
      "dontSave": "Don't save",
      "cancel": "Cancel",
      "closeConfirm": "Do you really want to close?",
      "saveConfirm": "Do you want to save your changes?",
      "lostModifications": "Your modifications will be lost.",
      "continue": "Continue",
      "yes": "Yes",
      "no": "No",
      "add": "Add",
      "pleaseWait": "Processing in progress, please wait.",
      "delete": "Delete",
      "requiredFields": "Required fields",
      "new": "New",
      "thisApplication": "This application",
      "previous": "Previous",
      "next": "Next"
    },
    "input": {
      "iban": "IBAN",
      "bic": "BIC",
      "bban": "BBAN",
      "inss": "National number",
      "co2": "g/km",
      "incorrectchar": "Forbidden characters",
      "datePlaceholder": "DD/MM/YYYY",
      "rangeDatePlaceholder": "DD/MM/YYYY-DD/MM/YYYY",
      "monthPlaceholder": "MM/YYYY",
      "multiSelectFilterPlaceHolder": "Search",
      "multiSelectPlaceholder": "Choose",
      "multiSelectItemsLabel": "{0} items selected",
      "emptyFilterMessage": "No results found",
      "globalSearch": "Global search",
      "rowsPerPage": "Rows per page"
    },
    "errors": {
      "success": "Success",
      "error": "Error",
      "warning": "Warning",
      "info": "Info",
      "genericNotification": "Validation failed.",
      "notificationSingular": "There is 1 field to check in order to continue.",
      "notificationPlural": "There are {{nrOfErrors}} fields to check in order to continue.",
      "required": "This field is mandatory",
      "inss": "No valid National number",
      "requiredInssForBelgian": "National number is required for Belgian citizens",
      "iban": "Not a valid IBAN",
      "bic": "Not a valid BIC",
      "bban": "Not a valid BBAN",
      "bbanMod97": "Not a valid BBAN",
      "email": "Not a valid email address",
      "emailAddressAlreadyExists": "Email address already exists.",
      "min": "Value cannot be lower than {{min}}. Was '{{actual}}'",
      "max": "Value cannot be higher than {{max}}. Was '{{actual}}'",
      "dateFormat": "Invalid format",
      "dateRangeFormat": "Invalid format",
      "monthFormat": "Invalid format",
      "maxdate": "Date must be before max date",
      "mindate": "Date must be after min date",
      "futureDate": "The national number contains a date of birth in the future. This is not possible.",
      "maxlength": "Input cannot be longer than {{requiredLength}} characters",
      "minlength": "Input cannot be shorter than {{requiredLength}} characters",
      "pattern": "Input does not match required pattern",
      "payforceCharacter": "Character '{{char}}' is not allowed.",
      "payforceCharacters": "Characters '{{chars}}' are not allowed.",
      "minimumDateError": "Start and end date must be a date within the last 3 years"
    },
    "portalBar": {
      "selectPU": "Select a payroll unit",
      "noResults": "No results found",
      "payrollUnit": "Payroll unit",
      "enterprise": "Enterprise",
      "min3char": "min. 3 char.",
      "logout": "Logout",
      "title": "My Partena Professional",
      "logout2": "Log out",
      "confirmTitle": "You are about to log out.",
      "cancel": "Cancel",
      "confirmMessage": "You will be redirected to Partena Professional’s commercial website.",
      "languages": {
        "en": "English",
        "fr": "Français",
        "nl": "Nederlands"
      }
    },
    "plainText": {
      "noIE": {
        "title": "IE browser not supported",
        "message": "{{app}} does not support Internet Explorer.\nPlease use Google Chrome, Microsoft Edge or Safari\n to use the application.",
        "firstLine": "{{app}} does not support Internet Explorer.",
        "secondLine": "Please use Google Chrome, Microsoft Edge or Safari to use the application."
      }
    },
    "disclaimer": {
      "title": "Terms of Service",
      "accept": "Accept",
      "decline": "Decline",
      "print": "Print"
    },
    "table": {
      "actions": "Actions",
      "export": "Export",
      "filters": "Filter",
      "search": "Search",
      "clearFilters": "Clear filters",
      "applyFilters": "Apply filters"
    }
  },
  "primeng": {
    "dayNames": [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    "dayNamesShort": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    "dayNamesMin": ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
    "monthNames": [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    "monthNamesShort": [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    "today": "Today",
    "clear": "Clear",
    "firstDayOfWeek": 1
  }
}
