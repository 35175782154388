<cdh-ui-card
  [title]="'customers.tabs.environment.content.processedFeedback.filesTable.title'">
  <p-table [value]="modifiedFiles()"> <!-- this is an array of files -->
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="fileName">{{'customers.tabs.environment.content.processedFeedback.filesTable.fileName' | translate}} <p-sortIcon field="fileName"></p-sortIcon></th>
        <th pSortableColumn="updated">{{'customers.tabs.environment.content.processedFeedback.filesTable.updated' | translate}} <p-sortIcon field="updated"></p-sortIcon></th>
        <th pSortableColumn="inserted">{{'customers.tabs.environment.content.processedFeedback.filesTable.inserted' | translate}} <p-sortIcon field="inserted"></p-sortIcon></th>
        <th pSortableColumn="deleted">{{'customers.tabs.environment.content.processedFeedback.filesTable.deleted' | translate}} <p-sortIcon field="deleted"></p-sortIcon></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-file let-i="rowIndex">
      <tr>
        <td [attr.data-token]="'isamChangeRow-' + i + '-fileName'">{{file.fileName}}</td>
        <td [attr.data-token]="'isamChangeRow-' + i + '-updated'">{{file.updated | number}}</td>
        <td [attr.data-token]="'isamChangeRow-' + i + '-inserted'">{{file.inserted | number}}</td>
        <td [attr.data-token]="'isamChangeRow-' + i + '-deleted'">{{file.deleted | number}}</td>
      </tr>
    </ng-template>
  </p-table>
</cdh-ui-card>
