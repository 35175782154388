import { NavbarUIConfig } from '@epg-apps/epg-ui-navbar';
import { PortalBarCompanyModel } from '@partena/uxlib-core';

const companies: PortalBarCompanyModel[] = [
  {
    kboBceNumber: '0',
    companyName: 'Company Name',
    payrollUnitId: '0055503',
    payrollUnitName: 'ABC ACCOUNTANCY BOURGEOIS & PARTNERS',
    payrollUnitOrigin: 'GPA',
    isAllowed: false,
  },
  {
    kboBceNumber: '0',
    companyName: 'Company Name',
    payrollUnitId: '0055504',
    payrollUnitName: 'MICHAEL BRACKE ADVOCATENK.BVBA',
    payrollUnitOrigin: 'GPA',
    isAllowed: true,
  },
  {
    kboBceNumber: '0',
    companyName: 'Company Name',
    payrollUnitId: '00055501',
    payrollUnitName: 'Dossier B',
    payrollUnitOrigin: 'GPA',
    isAllowed: false,
  },
  {
    kboBceNumber: '0',
    companyName: 'Company Name',
    payrollUnitId: '0055502',
    payrollUnitName: 'Dossier C',
    payrollUnitOrigin: 'OPA',
    isAllowed: true,
  },
];
export const CDHPrimaryMenuConfig: NavbarUIConfig = {
  applicationName: 'Apps',
  companies,
  selectedPU: {
    // kboBceNumber: '0',
    // companyName: 'Company Name',
    // payrollUnitId: '0055503',
    // payrollUnitName: 'ACCOUNTANCY BOURGEOIS & PARTNERS',
    // payrollUnitOrigin: 'GPA',
    // isAllowed: true,

    kboBceNumber: '0',
    companyName: 'Easypay Group',
    payrollUnitId: '0055503',
    payrollUnitName: 'Easypay Group - CDH ',
    payrollUnitOrigin: 'GPA',
    isAllowed: true,
  },
};
