import { Component, inject, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardFieldComponent } from '@epg-apps/cdh-ui-card-field';
import { CustomerDetail, CustomerService } from '@epg-apps/cdh-customers-data-access';
import { TranslateModule } from '@ngx-translate/core';
import { UiCardComponent } from '@epg-apps/cdh-ui-card';

@Component({
  selector: 'cdh-ui-customer-card-commercial-status',
  standalone: true,
  imports: [
    CommonModule,
    CardFieldComponent,
    TranslateModule,
    UiCardComponent,
  ],
  templateUrl: './customer-card-commercial-status.component.html',
  styleUrl: './customer-card-commercial-status.component.scss',
})
export class CustomerCardCommercialStatusComponent {
  customerDetail: Signal<CustomerDetail> =
    inject(CustomerService).customerDetail;
}
