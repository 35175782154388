<!-- Primary Menu -->
<pux-portal-bar
  (languageChanged)="onLanguageChange($event)"
  [companies]="config()?.companies!"
  [selectedPU]="config()?.selectedPU!"
  [ngClass]="config() ?? 'hidden-config'"
/>
@if (logoutOption.isEnable) {

  <div class="buttons-nav-bar">
     <a [routerLink]="logoutOption.url">
      <epg-ui-button iconName="pi pi-sign-out text-white" color="primary"/>
     </a>
  </div>
}

<!-- Secondary Menu -->
<pux-nav-bar>
  <p-menubar [model]="navitems()">
    <ng-template pTemplate="start">
      @if (appName ) {
        <label class="mb-0 mr-5 pux-text-brand font-bold hidden lg:inline">
          {{ appName }}
        </label>
      }
    </ng-template>
  </p-menubar>
</pux-nav-bar>
