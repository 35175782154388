<cdh-ui-card [title]="'customers.tabs.environment.content.environmentCard.title'">
  <div class="grid">
    <cdh-ui-card-field
      [label]="'customers.tabs.environment.content.userEnvironmentCard.name'"
      [value]="technicalSoftwareInfo().userName"
    />
    <cdh-ui-card-field
      [label]="'customers.tabs.environment.content.userEnvironmentCard.homeDirectory'"
      [value]="technicalSoftwareInfo().homeDirectory"
    />
    <cdh-ui-card-field
      [label]="'customers.tabs.environment.content.userEnvironmentCard.workingDirectory'"
      [value]="technicalSoftwareInfo().workingDirectory"
      [drawLine]="false"
    />
  </div>
</cdh-ui-card>
