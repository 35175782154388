<nav
  class="bg-white border-round-bottom-lg sidebar-height"
  id="sidebar">
  <div id="sidebar-item-line-container1" class="scroll-area">
    <div id="sidebar-item-line-container2" class="scroll-area">
      <div *ngFor="let item of items(); let i = index">
        <cdh-ui-search-item
          (itemSelected)="onItemSelected($event)"
          [selectedItem]="selectedItem()"
          [item]="item"
          [attr.data-token]="'item-bar-' + i"
        >
        </cdh-ui-search-item>
      </div>
    </div>
  </div>
</nav>
