import {Component, input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiCardComponent} from "@epg-apps/cdh-ui-card";
import {ModifiedFile} from "@epg-apps/cdh-data-access-environment";
import {CardFieldComponent} from "@epg-apps/cdh-ui-card-field";
import {TranslateModule} from "@ngx-translate/core";
import {UxlibCoreModule} from "@partena/uxlib-core";

@Component({
  selector: 'cdh-ui-processed-feedback-card-files-table',
  standalone: true,
  imports: [CommonModule, UiCardComponent, CardFieldComponent, TranslateModule, UxlibCoreModule],
  templateUrl: './processed-feedback-card-files-table.component.html',
  styleUrl: './processed-feedback-card-files-table.component.scss',
})
export class ProcessedFeedbackCardFilesTableComponent {
  modifiedFiles = input.required<ModifiedFile[]>()
}
