<cdh-ui-card [title]="'customers.tabs.environment.content.typeCard.title'">
  <div *ngIf="canEdit(); else cannotEdit" class="grid" [formGroup]="formGroup">
    <p-dropdown
      class="flex col-12"
      [options]="sortedEnvironmentTypes()"
      optionValue="enumName"
      optionLabel="enumName"
      formControlName="selectedType"
    />
    <cdh-button-ui
      [label]="'customers.tabs.environment.content.typeCard.saveBtn' | translate"
      (click)="onSaveClicked()"
    />
  </div>
  <ng-template #cannotEdit>
    <div class="grid">
      <cdh-ui-card-field
        [label]="enumName()"
        [boldLabel]="false"
        [drawLine]="false"
      />
    </div>
  </ng-template>
</cdh-ui-card>
