<div class="flex col-12 flex-wrap justify-content-between">
  <label class="margin-right-10"
         *ngIf="label()"
         [class.bold]="boldLabel()">
    {{ label() | translate }}
  </label>
  <label *ngIf="value()" class="second" [ngSwitch]="valueType()">
    <ng-container *ngSwitchCase="inputType.string">
      {{ stringValue() }}
    </ng-container>
    <ng-container *ngSwitchCase="inputType.stringArray">
      <ul>
        <li *ngFor="let entry of stringArrayValue()">{{ entry }}</li>
      </ul>
    </ng-container>
    <ng-container *ngSwitchCase="inputType.number">
      {{ numberValue() | number }}
    </ng-container>
  </label>
<!--  TODO epg-status-chip wrapper for pux-status-chip-->
  <pux-status-chip
    *ngIf="showChip()"
    [name]="chipText() | translate"
    [className]="chipType() ?? ''"
    [hasError]="chipHasError()"
    [hasWarning]="chipHasWarning()"
  ></pux-status-chip>
</div>
<div class="col-12 line" *ngIf="drawLine()"></div>

