import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseEntity } from '@epg-apps/cdh-model-base';

@Component({
  selector: 'cdh-ui-info-bar',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './info-bar.component.html',
  styleUrl: './info-bar.component.scss',
})
export class InfoBarComponent {
  activeItem = input<BaseEntity>()
}
