import { inject, Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '@epg-apps/cdh-environments';
import { Router } from '@angular/router';
import { AuthenticationService } from '@epg-apps/cdh-authentication-iam';

@Injectable()
export class AuthenticationTokenInterceptor implements HttpInterceptor {
  private readonly cookieService = inject(CookieService);
  private readonly router = inject(Router);
  private readonly authenticationService = inject(AuthenticationService);

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (environment.bypassSecurity) return next.handle(req);
    const jwtToken = this.cookieService.get(
      AuthenticationService.mapCookieNameToEnvironment(
        AuthenticationService.EP_TOKEN
      )
    );
    const tokenizedRequest = req.clone({
      setHeaders: {
        Authorization: 'Bearer ' + jwtToken,
        'Ocp-Apim-Subscription-Key': environment.apimSubscriptionKey,
      },
    });
    return next.handle(tokenizedRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.authenticationService.login(this.router.url);
        }
        return throwError(() => error);
      })
    );
  }
}
