import { Component, input, output } from '@angular/core';
import { BaseEntity } from '@epg-apps/cdh-model-base';
import { CdhSearchItemComponent } from '@epg-apps/cdh-ui-search-item';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'cdh-ui-search-list',
  standalone: true,
  templateUrl: './search-list.component.html',
  styleUrl: './search-list.component.scss',
  imports: [CdhSearchItemComponent, CommonModule],
})
export class CdhSearchListComponent<T extends BaseEntity> {
  items = input<T[]>([]);
  selectedItem = input<T>();
  itemSelected = output<T>();

  onItemSelected($event: T) {
    this.itemSelected.emit($event);
  }
}
