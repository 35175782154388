<cdh-ui-card [title]="title()" [canExpand]="productsPresent()" [isExpanded]="productsPresent()">
  <div class="grid">
    <ng-container *ngFor="let product of products(); let last = last">
      <div class="flex col-12 flex-wrap justify-content-between">
        <div class="product-info">
          <label>{{ product.name }}</label>
          <pux-status-chip
            *ngIf="!product.active"
            [name]="'customers.tabs.environment.content.productsCard.inactive' | translate"
            [className]="'error'"
             />
        </div>
        <label class="second">{{ product.version }}</label>
      </div>
      <div class="col-12 line" *ngIf="!last"></div>
    </ng-container>
  </div>
</cdh-ui-card>
