import { Component, computed, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UiCardComponent} from "@epg-apps/cdh-ui-card";
import {CardFieldComponent} from "@epg-apps/cdh-ui-card-field";
import { ConnectionResult, DetailedEnvironment } from '@epg-apps/cdh-data-access-environment';

@Component({
  selector: 'cdh-ui-environment-card-connection-status',
  standalone: true,
  imports: [CommonModule, UiCardComponent, CardFieldComponent],
  templateUrl: './environment-card-connection-status.component.html',
  styleUrl: './environment-card-connection-status.component.scss',
})
export class EnvironmentCardConnectionStatusComponent {
  connectionResults = input.required<ConnectionResult[]>()
}
