<cdh-ui-card [title]="getMenuTitle()">
  <div class="grid">
    <cdh-ui-card-field
      [label]="'customers.tabs.environment.content.epgSoftwareMenuInfo.totalUsers'"
      [value]="formatUsers(totalUsers(), true)"
      [drawLine]="isNewMenu()"
    />
    <cdh-ui-card-field *ngIf="isNewMenu()"
                       [label]="'customers.tabs.environment.content.epgSoftwareMenuInfo.masterF1Users'"
                       [value]="formatUsers(masterF1Users())"
    />
    <cdh-ui-card-field *ngIf="isNewMenu()"
                       [label]="'customers.tabs.environment.content.epgSoftwareMenuInfo.convertedUsers'"
                       [value]="formatUsers(convertedUsers())"
                       [drawLine]="false"
    />
  </div>
</cdh-ui-card>
