import { InjectionToken } from "@angular/core";
import { NavbarUIConfig, NavMenuLinkItem } from "./epg-navbar-config";

export interface AppHeaderConfig {
    appName: string;
    headerMenu?: NavbarUIConfig;
    appMenu?: NavMenuLinkItem[];
    enableLogout?: boolean;
}

/**
 *  This token injects the application name.
 *  It is used to set the title of the application in the header.
 *  If the token is not provided, the default application name will be used.
 *  @example
 *  ```typescript
 *  { provide: APP_NAME_TOKEN, useValue: 'My App' }
 *  ```
 */

export const APP_NAME_TOKEN = new InjectionToken<string>('APP_NAME');

/**
 * This token injects the logout URL.
 * It is used to navigate to the logout page when the logout button is clicked.
 * If the token is not provided, the default logout URL will be used.
 * @example
 * ```typescript
 * { provide: ENABLE_LOGOUT_URL_TOKEN, useValue: true}
 * { provide: LOG_OUT_URL_TOKEN, useValue: '/logout' }
 * ```
 */
export const LOG_OUT_URL_TOKEN = new InjectionToken<string>('LOG_OUT_URL');

/**
 * This token injects the boolean value to enable or disable the logout button.
 * It is used to show or hide the logout button in the navbar.
 * If the token is not provided, the default value will be used.
 * @example
 * ```typescript
 * { provide: ENABLE_LOGOUT_URL_TOKEN, useValue: true }
 * ```
 */
export const ENABLE_LOGOUT_URL_TOKEN = new InjectionToken<boolean>('ENABLE_LOGOUT_URL_TOKEN');


export const APP_MENU_CONFIG_TOKEN = new InjectionToken<NavbarUIConfig[]>('APP_MENU_CONFIG_TOKEN');
export const APP_HEADER_CONFIG_TOKEN = new InjectionToken<AppHeaderConfig>('APP_HEADER_CONFIG');
