import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CdhHeaderUiComponent } from '@epg-apps/cdh-ui-header';
import { EpgNavbarUiComponent } from '@epg-apps/epg-ui-navbar';
import { CommonModule } from '@angular/common';
import { EpgContainerUiComponent } from '@epg-apps/epg-ui-container';
import { CdhFeatureHeaderComponent } from '@epg-apps/cdh-feature-header';
import { CdhFeatureCustomersOverviewComponent } from '@epg-apps/cdh-feature-customers-overview';
import { EpgTranslationsService, TranslationLocal } from '@epg-apps/util-translations';
import { TranslateModule } from '@ngx-translate/core';
import * as enUS from '../../public/i18n/en-US.json';

@Component({
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,

    EpgNavbarUiComponent,
    EpgNavbarUiComponent,
    EpgContainerUiComponent,
    CdhHeaderUiComponent,
    CdhFeatureHeaderComponent,
    CdhFeatureCustomersOverviewComponent,
    TranslateModule,
  ],
  selector: 'epg-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'cdh';
  translationService = inject(EpgTranslationsService);

  constructor() {
    this.translationService.setTranslation(TranslationLocal.EN, enUS)
    this.translationService.setDefaultLang(TranslationLocal.EN);
    this.translationService.use(TranslationLocal.EN);
  }
}
