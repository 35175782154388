<cdh-ui-card [title]="'customers.tabs.environment.content.userEnvironmentCard.title'" >
  <div class="grid">
    <cdh-ui-card-field
      [label]="'customers.tabs.environment.content.environmentCard.ip'"
      [value]="environment().systemInfo.ip"
    />
    <cdh-ui-card-field
      [label]="'customers.tabs.environment.content.environmentCard.macAddress'"
      [value]="environment().systemInfo.mac"
    />
    <cdh-ui-card-field
      [label]="'customers.tabs.environment.content.environmentCard.baseUrl'"
      [value]="environment().softwareInfo.technicalInfo.baseURL"
    />
    <cdh-ui-card-field
      [label]="'customers.tabs.environment.content.environmentCard.webServer'"
      [value]="environment().softwareInfo.technicalInfo.webServer"
    />
    <cdh-ui-card-field
      [label]="'customers.tabs.environment.content.environmentCard.JDKS'"
      [value]="jdks()"
    />
    <cdh-ui-card-field
      [label]="'customers.tabs.environment.content.environmentCard.hostName'"
      [value]="environment().systemInfo.hostname"
    />
    <cdh-ui-card-field
      [label]="'customers.tabs.environment.content.environmentCard.os'"
      [value]="environment().systemInfo.os"
    />
    <cdh-ui-card-field
      [label]="'customers.tabs.environment.content.environmentCard.tomcatVersion'"
      [value]="environment().softwareInfo.technicalInfo.tomcatVersion"
    />
    <cdh-ui-card-field
      [label]="'customers.tabs.environment.content.environmentCard.cobolVersion'"
      [value]="environment().softwareInfo.technicalInfo.cobolInfo.version"
    />
    <cdh-ui-card-field
      *ngIf="environment().softwareInfo.technicalInfo.cloudLinkInfo.version as cloudLinkVersion"
      [label]="'customers.tabs.environment.content.environmentCard.cloudLinkVersion'"
      [value]="cloudLinkVersion"
      [drawLine]="false"
    />
  </div>
</cdh-ui-card>
