import { AfterContentInit, Component, ContentChildren, QueryList } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabViewModule } from 'primeng/tabview';
import { EpgTabPanelComponent } from '@epg-apps/epg-tab-panel';
import { TranslateModule } from '@ngx-translate/core';


/**
 * The `epg-tab-view` component is a wrapper around the PrimeNG `p-tabView` component. 
 * It provides a convenient way to create a tabbed interface. This component is also support ngx translation
 * 
 * 
 * @example
 * ```html
 * <epg-tab-view>
 *   <epg-tab-panel header="Tab 1">
 *     <ng-template> 
 *       Content of Tab 1 
 *     </ng-template>
 *   </epg-tab-panel>
 *   <epg-tab-panel header="Tab 2">
 *     <ng-template> 
 *       Content of Tab 2 
 *     </ng-template>
 *   </epg-tab-panel>
 * </epg-tab-view>
 * ```
 * @example
 * To able to use with translation make sure to add single quote on the header attribute
 * Sample code: 
 * ```html
 * [header]="'customers.tabs.general.title'"
 * ````
 * 
 *  * ```html
 * <epg-tab-view>
 *   <epg-tab-panel [header]="'customers.tabs.general.title'">
 *     <ng-template> 
 *       Content of Tab 1 
 *     </ng-template>
 *   </epg-tab-panel>
 *   <epg-tab-panel header="Tab 2">
 *     <ng-template> 
 *       Content of Tab 2 
 *     </ng-template>
 *   </epg-tab-panel>
 * </epg-tab-view>
 * ```
 */

@Component({
  selector: 'epg-tab-view',
  standalone: true,
  imports: [CommonModule, TabViewModule, TranslateModule, EpgTabPanelComponent],
  template: `
    <p-tabView>
      <p-tabPanel *ngFor="let tab of tabs" [header]="tab.header() | translate">
        <ng-template [ngTemplateOutlet]="tab.templateRef"></ng-template>
      </p-tabPanel>
    </p-tabView>
  `,

})
export class EpgTabViewComponent  implements AfterContentInit  {
  @ContentChildren(EpgTabPanelComponent) tabContents!: QueryList<EpgTabPanelComponent>;

  tabs: EpgTabPanelComponent[] = [];

  ngAfterContentInit() {
    this.tabs = this.tabContents.toArray();
  }
}
