<cdh-ui-card [title]="'customers.tabs.environment.content.javaCard.title'">
  <div class="grid">
    <cdh-ui-card-field
      [label]="'customers.tabs.environment.content.javaCard.javaVersion'"
      [value]="javaEnvironment().version"
    />
    <cdh-ui-card-field
      [label]="'customers.tabs.environment.content.javaCard.javaVendor'"
      [value]="javaEnvironment().vendor"
    />
    <cdh-ui-card-field
      [label]="'customers.tabs.environment.content.javaCard.javaDirectory'"
      [value]="javaEnvironment().installationDirectory"
    />
    <cdh-ui-card-field
      [label]="'customers.tabs.environment.content.javaCard.processors'"
      [value]="javaEnvironment().processors"
    />
    <cdh-ui-card-field
      [label]="'customers.tabs.environment.content.javaCard.maxMemory'"
      [value]="(maxMemory() | number: '1.0-3') + ' ' + (maxMemoryUnit() | translate)"
    />
    <cdh-ui-card-field
      [label]="'customers.tabs.environment.content.javaCard.totalMemory'"
      [value]="(totalMemory() | number: '1.0-3') + ' ' + (totalMemoryUnit() | translate)"
      [drawLine]="false"
    />
  </div>
</cdh-ui-card>
