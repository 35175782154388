import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerCardProfileComponent } from '../ui-customer-card-profile/customer-card-profile.component';
import { CustomerCardCommercialStatusComponent } from '../ui-customer-card-commercial-status/customer-card-commercial-status.component';

@Component({
  selector: 'cdh-ui-customer-general',
  standalone: true,
  imports: [
    CommonModule,
    CustomerCardProfileComponent,
    CustomerCardCommercialStatusComponent,
  ],
  templateUrl: './customer-general.component.html',
  styleUrl: './customer-general.component.scss',
})
export class CustomerGeneralComponent {
}
