import {Component, Signal, OnInit, inject, computed, signal} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  CustomerService,
  Customer,
  CustomerDetail,
} from '@epg-apps/cdh-customers-data-access';
import {CdhSearchFilterComponent} from '@epg-apps/cdh-ui-search-filter';
import {CdhSearchListComponent} from '@epg-apps/cdh-ui-search-list';
import {InfoBarComponent} from '@epg-apps/cdh-ui-info-bar';
import {CustomerGeneralComponent} from '@epg-apps/cdh-ui-customer';
import {EpgTabViewComponent} from '@epg-apps/epg-tab-view';
import {EpgTabPanelComponent} from '@epg-apps/epg-tab-panel';
import { ActivatedRoute, Router } from '@angular/router';
import {SwitcherComponent} from '@epg-apps/cdh-ui-switcher';
import {TranslateModule} from '@ngx-translate/core';
import {CdhButtonUiComponent} from "@epg-apps/cdh-ui-button";
import {UxlibCoreModule} from "@partena/uxlib-core";
import {ToastModule} from 'primeng/toast';
import {DataAccessCustomerExportService} from "@epg-apps/cdh-data-access-customer-export";
import {EnvironmentService} from "@epg-apps/cdh-data-access-environment";
import {EnvironmentGeneralComponent} from "@epg-apps/cdh-ui-environment";
import {AuthenticationService} from "@epg-apps/cdh-authentication-iam";
import {HttpResponse} from "@angular/common/http";

@Component({
  selector: 'cdh-customers-overview',
  standalone: true,
  imports: [
    CommonModule,
    CdhSearchFilterComponent,
    CdhSearchListComponent,
    InfoBarComponent,
    CustomerGeneralComponent,
    EpgTabViewComponent,
    EpgTabPanelComponent,
    SwitcherComponent,
    TranslateModule,
    CdhButtonUiComponent,
    UxlibCoreModule,
    ToastModule,
    EnvironmentGeneralComponent
  ],
  templateUrl: './cdh-feature-customers-overview.component.html',
  styleUrl: './cdh-feature-customers-overview.component.scss',
})
export class CdhFeatureCustomersOverviewComponent implements OnInit {
  protected buttonText: Signal<string> = computed(() => this.dynamicButtonLabel());
  protected isDownloading = signal<boolean>(false);
  private readonly customerService = inject(CustomerService);
  protected readonly customers: Signal<Customer[]> = this.customerService.customers;
  protected readonly activeCustomer: Signal<CustomerDetail> = this.customerService.customerDetail;
  private readonly dataAccessCustomerExportService = inject(DataAccessCustomerExportService)
  private readonly environmentService = inject(EnvironmentService);
  private readonly router: Router = inject(Router);
  private readonly authenticationService = inject(AuthenticationService)
  private readonly route: ActivatedRoute = inject(ActivatedRoute);

  ngOnInit() {
    this.authenticationService.getUserProfile()
    this.environmentService.getEnvironmentTypes();
    this.customerService.getCustomers();

    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      this.setActiveCustomer(id);
    });
  }

  onCustomerSelected(id?: string) {
    this.router.navigate([], {queryParams: {id: id}});
  }

  private setActiveCustomer(id?: string) {
    if (id) {
      this.customerService.getCustomer(id);
      this.environmentService.getCustomerEnvironments(id);
    }
  }

  exportCustomers() {
    try {
      if (!this.isDownloading()) {
        this.isDownloading.set(true)
        this.dataAccessCustomerExportService.downloadCustomers().subscribe({
          next: (response: HttpResponse<Blob>) => {
            const contentDisposition = response.headers.get('Content-Disposition')!;
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(response.body!);
            link.download = contentDisposition.substring(contentDisposition.indexOf("=") + 1);
            link.click();
            link.remove();
            this.isDownloading.set(false);
          }
        });
      }
    } catch (error) {
      console.error('Download error', error);
      this.isDownloading.set(false);
    }
  }

  dynamicButtonLabel() {
    if (this.isDownloading()) {
      return "Downloading...";
    }
    return 'Export data';
  }

  onSearchChange(filterString: string) {
    this.customerService.getCustomers(filterString);
  }

  switchCustomer($event: string) {
    const id: string = this.getCustomerForLeftOrRight($event);
    this.router.navigate([], {queryParams: {id: id}});
  }

  private getCustomerForLeftOrRight($event: string): string {
    const data: Customer[] = this.customers();
    const id = this.activeCustomer().id;
    const index = data.findIndex((item) => item.id === id);
    switch ($event) {
      case 'right':
        return data[(index + 1) % data.length].id;
      case 'left':
        return data[(index === 0 ? data.length : index) - 1].id;
      default:
        return data[index].id;
    }
  }
}
