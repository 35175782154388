import { Component, computed, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UiCardComponent} from "@epg-apps/cdh-ui-card";
import {CardFieldComponent} from "@epg-apps/cdh-ui-card-field";
import { DetailedEnvironment, TechnicalSoftwareInfo } from '@epg-apps/cdh-data-access-environment';

@Component({
  selector: 'cdh-ui-environment-card-user-environment',
  standalone: true,
  imports: [CommonModule, UiCardComponent, CardFieldComponent],
  templateUrl: './environment-card-user-environment.component.html',
  styleUrl: './environment-card-user-environment.component.scss',
})
export class EnvironmentCardUserEnvironmentComponent {
  technicalSoftwareInfo = input.required<TechnicalSoftwareInfo>();
}
