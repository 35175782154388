import {Component, EventEmitter, Input, Output} from '@angular/core';
import { NgStyle } from '@angular/common';
import { CdhButtonUiComponent  } from '@epg-apps/cdh-ui-button';

export const NavigationDirections = {
  RIGHT: 'right',
  LEFT: 'left',
};

export const Display = {
  INLINE: 'inline',
  BLOCK: 'block',
};
@Component({
  selector: 'cdh-ui-switcher',
  standalone: true,
  templateUrl: './ui-switcher.component.html',
  styleUrl: './ui-switcher.component.scss',
  imports: [NgStyle, CdhButtonUiComponent],
})
export class SwitcherComponent {
  @Output() switch = new EventEmitter<string>();
  @Input() display = Display.BLOCK;
  @Input() disableLeft = false;
  @Input() disableRight = false;

  navigateRight(): void {
    if (this.disableRight) {
      return;
    }

    this.switch.emit(NavigationDirections.RIGHT);
  }

  navigateLeft(): void {
    if (this.disableLeft) {
      return;
    }
    this.switch.emit(NavigationDirections.LEFT);
  }
}
