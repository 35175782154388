import { Component, computed, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiCardComponent } from '@epg-apps/cdh-ui-card';
import { CardFieldComponent } from '@epg-apps/cdh-ui-card-field';
import { Product } from '@epg-apps/cdh-data-access-environment';
import { TranslateModule } from '@ngx-translate/core';
import { UxlibCoreModule } from '@partena/uxlib-core';

@Component({
  selector: 'cdh-ui-environment-card-products',
  standalone: true,
  imports: [
    CommonModule,
    UiCardComponent,
    CardFieldComponent,
    TranslateModule,
    UxlibCoreModule,
  ],
  templateUrl: './environment-card-products.component.html',
  styleUrl: './environment-card-products.component.scss',
})
export class EnvironmentCardProductsComponent {
  private readonly NO_PRESENT: string =
    'customers.tabs.environment.content.productsCard.noPresent';
  private readonly ONE_PRESENT: string =
    'customers.tabs.environment.content.productsCard.onePresent';
  private readonly MANY_PRESENT: string =
    'customers.tabs.environment.content.productsCard.manyPresent';

  productsPresent = computed<boolean>(() => this.products().length > 0);
  products = input.required<Product[]>();
  title = computed<string>(() => {
    if (this.products().length === 0) {
      return this.NO_PRESENT;
    } else if (this.products().length === 1) {
      return this.ONE_PRESENT;
    } else {
      return this.MANY_PRESENT;
    }
  });
}
