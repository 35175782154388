<div class="wrapper">
  <cdh-button-ui
    [name]="'btnLeft'"
    [icon]="'fas fa-chevron-left'"
    [disabled]="disableLeft"
    (click)="navigateLeft()"
  ></cdh-button-ui>
  <cdh-button-ui
    [name]="'btnRight'"
    [icon]="'fas fa-chevron-right'"
    [disabled]="disableRight"
    (click)="navigateRight()"
  ></cdh-button-ui>
</div>
