import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'epg-container-ui',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './epg-container-ui.component.html',
  styleUrl: './epg-container-ui.component.scss',
})
export class EpgContainerUiComponent {}
