import { Component, computed, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiCardComponent } from '@epg-apps/cdh-ui-card';
import { CardFieldComponent } from '@epg-apps/cdh-ui-card-field';
import { TranslateModule } from '@ngx-translate/core';
import { SystemInfo } from '@epg-apps/cdh-data-access-environment';

@Component({
  selector: 'cdh-ui-environment-card-local-runtimes',
  standalone: true,
  imports: [CommonModule, UiCardComponent, CardFieldComponent, TranslateModule],
  templateUrl: './environment-card-local-runtimes.component.html',
  styleUrl: './environment-card-local-runtimes.component.scss',
})
export class EnvironmentCardLocalRuntimesComponent {
  private readonly sizeNames: string[] = [
    'customers.tabs.environment.content.localRunTimeCard.memorySize.byte',
    'customers.tabs.environment.content.localRunTimeCard.memorySize.kiloByte',
    'customers.tabs.environment.content.localRunTimeCard.memorySize.megaByte',
    'customers.tabs.environment.content.localRunTimeCard.memorySize.gigaByte',
    'customers.tabs.environment.content.localRunTimeCard.memorySize.teraByte',
  ];

  systemInfo = input.required<SystemInfo>();

  protected totalSystemMemoryFormated = computed<number>(() =>
    this.formatMemorySize(this.systemInfo().totalMemory)
  );
  protected totalSystemMemoryUnit = computed<string>(() =>
    this.getMemoryUnit(this.systemInfo().totalMemory)
  );

  protected availableSystemMemoryFormatted = computed<number>(() =>
    this.formatMemorySize(this.systemInfo().availableMemory)
  );
  protected availableSystemMemoryUnit = computed<string>(() =>
    this.getMemoryUnit(this.systemInfo().availableMemory)
  );

  private formatMemorySize(memorySize: number): number {
    const i: number =
      memorySize == 0 ? 0 : Math.floor(Math.log(memorySize) / Math.log(1024));
    return memorySize / Math.pow(1024, i);
  }

  private getMemoryUnit(memorySize: number): string {
    const i: number =
      memorySize == 0 ? 0 : Math.floor(Math.log(memorySize) / Math.log(1024));
    return this.sizeNames[i];
  }
}
