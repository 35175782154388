<cdh-ui-card
  [canExpand]="errorMessagePresent()"
  [isExpanded]="errorMessagePresent()"
  title="{{(errorMessagePresent() ?
        'customers.tabs.environment.content.processedFeedback.errorCard.present' :
        'customers.tabs.environment.content.processedFeedback.errorCard.noPresent') | translate
}}">
  <div class="grid">
    <ng-container *ngIf="errorMessagePresent()">
      <cdh-ui-card-field
        [attr.data-token]="'processFeedbackErrorCode'"
        [label]="processedFeedback().error!.code"
        [drawLine]="false"
      />
      <cdh-ui-card-field
        [attr.data-token]="'processFeedbackErrorMessage'"
        [label]="processedFeedback().error!.message"
        [boldLabel]="false"
        [drawLine]="false"
      />
    </ng-container>
  </div>
</cdh-ui-card>
