<ng-container *ngIf="sortedProcessedFeedbacks().length > 0;">
  <div class="p-2">
    <h4>{{ 'customers.tabs.environment.content.processedFeedback.title' | translate }}</h4>
    <p-accordion [multiple]="true">
      <p-accordionTab *ngFor="let processedFeedback of sortedProcessedFeedbacks(); let i = index"
                      [contentStyleClass]="'surface-ground'"
                      [headerStyleClass]="'surface-0'"
                      [attr.data-token]="'processFeedbackAccordionTab-' + i">
        <ng-template pTemplate="header">
          <span>{{ START_TIME | translate }}: {{ processedFeedback.formattedStartTime }} - {{
              PROCESSED_TIME | translate
            }}: {{ processedFeedback.formattedProcessingTime }} {{ SECONDS | translate }}</span>
        </ng-template>
        <ng-template pTemplate="content">
          <div class="p-2">
            <div class="grid">
              <div class="col-12">
                <cdh-ui-processed-feedback-card-files-table [modifiedFiles]="processedFeedback.updatedFiles.modifiedFiles"/>
              </div>
              <div class="col-12 line"></div>
              <div class="col-12 xl:col-6">
                <div class="flex flex-column">
                  <cdh-ui-processed-feedback-warnings [processedFeedback]="processedFeedback"/>
                </div>
              </div>
              <div class="col-12 xl:col-6">
                <div class="flex flex-column">
                  <cdh-ui-processed-feedback-errors [processedFeedback]="processedFeedback"/>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </p-accordionTab>
    </p-accordion>
  </div>
</ng-container>
