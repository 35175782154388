<cdh-ui-card [title]="'customers.tabs.environment.content.localRunTimeCard.title'">
  <div class="grid">
    <cdh-ui-card-field
      [label]="'customers.tabs.environment.content.localRunTimeCard.totalSystemMemory'"
      [value]="(totalSystemMemoryFormated() | number: '1.0-3') + ' ' + (totalSystemMemoryUnit() | translate)"
    />
    <cdh-ui-card-field
      [label]="'customers.tabs.environment.content.localRunTimeCard.availableSystemMemory'"
      [value]="(availableSystemMemoryFormatted() | number: '1.0-3') + ' '+ (availableSystemMemoryUnit() | translate)"
      [drawLine]="false"
    />
  </div>
</cdh-ui-card>
