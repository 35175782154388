<div class="flex align-items-start">
  <div class="employee-list right lg:pt-4 lg:pl-4">
    <div class="p-4 bg-white border-round-top-lg">
      <cdh-ui-search-filter
        (searchChange)="onSearchChange($event)">
      </cdh-ui-search-filter>
    </div>
    <cdh-ui-search-list [items]="customers()"
                        [selectedItem]="activeCustomer()"
                        (itemSelected)="onCustomerSelected($event.id)"
                        id="item-bar">
    </cdh-ui-search-list>
  </div>
  <div
    *ngIf="activeCustomer()" class="right-panel lg:p-3 lg:pb-0">
    <div class="header col-12 p-3 lg:p-0">
      <cdh-ui-info-bar *ngIf="customers()" id="customer-infoBar" [activeItem]="activeCustomer()">
        <cdh-ui-switcher (switch)="switchCustomer($event)"></cdh-ui-switcher>
        <cdh-button-ui [label]="buttonText()" [loading]="isDownloading()" (click)="exportCustomers()" [disabled]="isDownloading()" icon="fas fa-file-export"></cdh-button-ui>
      </cdh-ui-info-bar>
      <epg-tab-view>
        <epg-tab-panel header="customers.tabs.general.title">
          <ng-template>
            <cdh-ui-customer-general id="customer-general"/>
          </ng-template>
        </epg-tab-panel>
        <epg-tab-panel header="customers.tabs.environment.title">
          <ng-template>
            <cdh-ui-environment-general id="environment-general"/>
          </ng-template>
        </epg-tab-panel>
      </epg-tab-view>
    </div>
  </div>
</div>
