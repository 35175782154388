import { Component, input, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonDirective, ButtonModule } from 'primeng/button';
import { UiButtonTypeDirective } from './ui-button.type-directive';
/**
 * Button UI
 * This component display a button and the options to show.
 * This component containts eventEmitter to able to emit the function that you want to redirect.
 *
 * @Input label: String - Optional
 * @Input disable: Boolean - Optional
 * @Input color: "primary" | "secondary" | "warning" - Optional
 * @Input type: default" | "text" | "link" | "rounded" - Optional
 *
 * @Output clickEvent
 * 
 * @Output onLogout: string
 *
 * @example
 *  <epg-button-ui [disable]="false"
    color="primary"
    type="rounded"
    (clickEvent)="onLogoutEvent()"
    ></epg-button-ui>
 */

@Component({
  selector: 'epg-ui-button',
  standalone: true,
  imports: [CommonModule, ButtonModule, UiButtonTypeDirective],
  templateUrl: './ui-button.component.html',
  styleUrl: './ui-button.component.scss',
  
})
export class EpgUiButtonComponent {

  disable = input<boolean>(false);
  clickEvent = output();
  color = input<ButtonDirective['severity']>();
  type = input<"default" | "text" | "rounded">("default");

  onClickEvent(){
    this.clickEvent.emit();
  }
}
