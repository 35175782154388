<div class="info-bar flex flex-grow-1 align-items-center">
  <div class="info-area flex flex-column">
    <div class="name-area">
      <div class="info-name text-xl md:text-2xl">{{ activeItem()?.title }} </div>
      <div class="text-right">
        <ng-content></ng-content>
      </div>
    </div>
    <div class="info pt-1">{{ activeItem()?.description }}</div>
  </div>
</div>
