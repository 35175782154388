import {Component, computed, inject, input, OnInit} from '@angular/core';
import {CommonModule, formatPercent} from '@angular/common';
import {UiCardComponent} from "@epg-apps/cdh-ui-card";
import {CardFieldComponent} from "@epg-apps/cdh-ui-card-field";
import {TranslateModule} from "@ngx-translate/core";
import {MenuInfo} from "../../../../data-access-environment/src/lib/data-access-environment/functionalSoftwareInfo";
import {EpgTranslationsService} from "@epg-apps/util-translations";

@Component({
  selector: 'cdh-ui-environment-card-menu-info',
  standalone: true,
  imports: [CommonModule, UiCardComponent, CardFieldComponent, TranslateModule],
  templateUrl: './environment-card-menu-info.component.html',
  styleUrl: './environment-card-menu-info.component.scss',
})
export class EnvironmentCardMenuInfoComponent {

  menuInfo = input.required<MenuInfo>()
  protected isNewMenu = computed<boolean>(() => this.menuInfo().isNewMenu)
  protected totalUsers = computed<number>(() => this.menuInfo().totalUsers)
  protected masterF1Users = computed<number>(() => this.menuInfo().masterF1Users)
  protected convertedUsers = computed<number>(() => this.menuInfo().convertedUsers)
  translationService = inject(EpgTranslationsService);
  protected locale = 'en-US'
  protected digitsInfo = '1.0'
  protected readonly formatPercent = formatPercent;

  protected getMenuTitle = () => {
    return this.isNewMenu() ? 'customers.tabs.environment.content.epgSoftwareMenuInfo.newTitle' : 'customers.tabs.environment.content.epgSoftwareMenuInfo.oldTitle'
  }
  protected formatUsers = (users: number, withOutPercentage: boolean = false) => {
    if (users == 0) {
      return this.translationService.getTranslation('customers.tabs.environment.content.epgSoftwareMenuInfo.noData');
    }
    if (withOutPercentage) {
      return users;
    }
    const percentage = formatPercent(users / this.totalUsers(), this.locale, this.digitsInfo)
    return `${percentage} (${users})`
  }
}
