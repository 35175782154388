<cdh-ui-card
  title="customers.tabs.general.content.profileCard.title">
  <div class="grid">
    <cdh-ui-card-field
      [label]="customerDetail().name"
      [boldLabel]="false"
    ></cdh-ui-card-field>
    <cdh-ui-card-field
      [label]="'customers.tabs.general.content.profileCard.address'"
      [drawLine]="false"
    ></cdh-ui-card-field>
    <cdh-ui-card-field
      [label]="customerDetail().address"
      [boldLabel]="false"
      [drawLine]="false"
    ></cdh-ui-card-field>
    <cdh-ui-card-field
      [label]="customerDetail().postalCode"
      [value]="customerDetail().city"
      [boldLabel]="false"
      [drawLine]="customerDetail().country.length === 0"
    ></cdh-ui-card-field>
    <cdh-ui-card-field
      *ngIf="customerDetail().country.length > 0"
      [label]="countryCode() "
      [boldLabel]="false"
    ></cdh-ui-card-field>
    <cdh-ui-card-field
      *ngIf="customerDetail().enterpriseNumber"
      [label]="'customers.tabs.general.content.profileCard.enterpriseNumber'"
      [value]="customerDetail().enterpriseNumber"
      [drawLine]="false"
    ></cdh-ui-card-field>
    <cdh-ui-card-field
      *ngIf="customerDetail().responsiblePerson"
      [label]="'customers.tabs.general.content.profileCard.responsible'"
      [value]="customerDetail().responsiblePerson"
      [drawLine]="false"
    ></cdh-ui-card-field>
  </div>
</cdh-ui-card>
