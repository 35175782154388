import { Component, computed, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UiCardComponent} from "@epg-apps/cdh-ui-card";
import {CardFieldComponent} from "@epg-apps/cdh-ui-card-field";
import {DetailedEnvironment} from "@epg-apps/cdh-data-access-environment";

@Component({
  selector: 'cdh-ui-environment-card-overview',
  standalone: true,
  imports: [CommonModule, UiCardComponent, CardFieldComponent],
  templateUrl: './environment-card-overview.component.html',
  styleUrl: './environment-card-overview.component.scss',
})
export class EnvironmentCardOverviewComponent {
  environment = input.required<DetailedEnvironment>();
  jdks = computed<string>(() => this.environment().softwareInfo.technicalInfo.jdks.map(x => x.version).join(', '));
}
