import {Component, computed, inject, input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiCardComponent} from "@epg-apps/cdh-ui-card";
import {DetailedEnvironment, EnvironmentService} from "@epg-apps/cdh-data-access-environment";
import {CardFieldComponent} from "@epg-apps/cdh-ui-card-field";
import {TranslateModule} from "@ngx-translate/core";
import {UxlibCoreModule} from "@partena/uxlib-core";
import {SortableEnum} from "@epg-apps/cdh-data-access-environment";
import {FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {CdhButtonUiComponent} from "@epg-apps/cdh-ui-button";
import {UpdateEnvironment} from "@epg-apps/cdh-data-access-environment";

@Component({
  selector: 'cdh-ui-environment-card-type',
  standalone: true,
  imports: [CommonModule, UiCardComponent, CardFieldComponent, TranslateModule, UxlibCoreModule, ReactiveFormsModule, CdhButtonUiComponent],
  templateUrl: './environment-card-type.component.html',
  styleUrl: './environment-card-type.component.scss',
})
export class EnvironmentCardTypeComponent implements OnInit {
  canEdit = input.required<boolean>();
  environment = input.required<DetailedEnvironment>();
  environmentTypes = inject(EnvironmentService).environmentTypeOptions;
  enumName = computed<string>(() => this.environment().softwareInfo.functionalInfo.designation.enumName);
  sortedEnvironmentTypes = computed<SortableEnum[]>(() => this.environmentTypes().sort((t1, t2) => t1.sortWeight - t2.sortWeight));
  environmentService = inject(EnvironmentService);

  formGroup: FormGroup = new FormGroup({});

  ngOnInit() {
    this.formGroup = new FormGroup({
      selectedType: new FormControl(this.environment().softwareInfo.functionalInfo.designation),
    })
  }

  protected onSaveClicked(): void {
    const update: UpdateEnvironment = {
      type: this.formGroup.get("selectedType")?.value,
    }
    this.environmentService.updateEnvironment(this.environment().b2cId, update)
  }
}
