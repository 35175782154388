import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
    selector: '[type]',
    standalone: true
  })

  export class UiButtonTypeDirective implements OnInit{
    @Input('type') styleType: 'default' | 'text' | 'rounded' = 'default';
    constructor(private el: ElementRef, private renderer: Renderer2) {}

    ngOnInit(): void {
        this.changeStyle()
    }

    private changeStyle(){

        let buttonClass = '';

        switch(this.styleType){
            case 'default': buttonClass = 'p-button-primary';
                break;
            case 'text': buttonClass = 'p-button-text';
                break;
            case 'rounded': buttonClass = 'p-button-rounded';
                break;
            default: buttonClass = 'btn btn-primary';
                break;
        }

        this.renderer.addClass(this.el.nativeElement, buttonClass);
    }

  }