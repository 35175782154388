<ng-container *ngIf="sortedEnvironments().length > 0; else noEnvironments">
  <div class="p-2">
    <p-accordion [multiple]="true" (onOpen)="loadEnvironmentDetails($event)" [(activeIndex)]="activeIndex">
      <p-accordionTab *ngFor="let environment of sortedEnvironments(); let i = index"
                      header="{{environment.type.enumName + ' - ' + environment.name}}"
                      [contentStyleClass]="'surface-ground'"
                      [headerStyleClass]="'surface-0'"
                      [attr.data-token]="'environment-' + i"
      >
        <ng-template pTemplate="content">
          <div class="p-2" *ngIf="environment.details as environmentDetail; else spinner">
            <div class="grid">
              <div class="col-12 lg:col-6 xl:col-4">
                <div class="flex flex-column">
                  <cdh-ui-environment-card-overview [environment]="environmentDetail"/>
                  <cdh-ui-environment-card-user-environment [technicalSoftwareInfo]="environmentDetail.softwareInfo.technicalInfo"/>
                  <cdh-ui-environment-card-menu-info [menuInfo]="environmentDetail.softwareInfo.functionalInfo.menuInfo"/>
                </div>
              </div>
              <div class="col-12 lg:col-6 xl:col-4">
                <div class="flex flex-column">
                  <cdh-ui-environment-card-products [products]="environmentDetail.softwareInfo.functionalInfo.products"/>
                  <cdh-ui-environment-card-local-runtimes [systemInfo]="environmentDetail.systemInfo"/>
                  <cdh-ui-environment-card-java [javaEnvironment]="environmentDetail.softwareInfo.technicalInfo.cloudLinkInfo.javaEnvironment"/>
                </div>
              </div>
              <div class="col-12 lg:col-6 xl:col-4">
                <div class="flex flex-column">
                  <cdh-ui-environment-card-last-update [environment]="environmentDetail"/>
                  <cdh-ui-environment-card-connection-status [connectionResults]="environmentDetail.softwareInfo.technicalInfo.cloudLinkInfo.connectionResults"/>
                  <cdh-ui-environment-card-type [environment]="environmentDetail" [canEdit]="canEdit()"/>
                </div>
              </div>
            </div>
            <div class="col-12 line"></div>
            <cdh-ui-processed-feedback-general [processedFeedbacks]="environmentDetail.softwareInfo.technicalInfo.cloudLinkInfo.processedFeedbacks"/>
          </div>
          <ng-template #spinner>
            <!--  <app-spinner class="p-2"></app-spinner>-->
          </ng-template>
        </ng-template>
      </p-accordionTab>
    </p-accordion>
  </div>
</ng-container>
<ng-template #noEnvironments>
  <div class="p-2">
    <p>{{ 'customers.tabs.environment.content.noEnvironments' | translate }}</p>
  </div>
</ng-template>
