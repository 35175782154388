import {Component, computed, input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiCardComponent} from "@epg-apps/cdh-ui-card";
import { ConnectionResult, DetailedEnvironment, JavaEnvironment } from '@epg-apps/cdh-data-access-environment';
import {CardFieldComponent} from "@epg-apps/cdh-ui-card-field";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'cdh-ui-environment-card-java',
  standalone: true,
  imports: [CommonModule, UiCardComponent, CardFieldComponent, TranslateModule],
  templateUrl: './environment-card-java.component.html',
  styleUrl: './environment-card-java.component.scss',
})
export class EnvironmentCardJavaComponent {
  private readonly sizeNames: string[] = [
    'customers.tabs.environment.content.localRunTimeCard.memorySize.byte',
    'customers.tabs.environment.content.localRunTimeCard.memorySize.kiloByte',
    'customers.tabs.environment.content.localRunTimeCard.memorySize.megaByte',
    'customers.tabs.environment.content.localRunTimeCard.memorySize.gigaByte',
    'customers.tabs.environment.content.localRunTimeCard.memorySize.teraByte'
  ]

  javaEnvironment = input.required<JavaEnvironment>()
  protected maxMemory = computed<number>(() => this.formatMemorySize(this.javaEnvironment().maxMemory))
  protected maxMemoryUnit = computed<string>(() => this.getMemoryUnit(this.javaEnvironment().maxMemory))

  protected totalMemory = computed<number>(() => this.formatMemorySize(this.javaEnvironment().totalMemory))
  protected totalMemoryUnit = computed<string>(() => this.getMemoryUnit(this.javaEnvironment().totalMemory))

  private formatMemorySize(memorySize: number): number {
    const i: number = memorySize == 0 ? 0 : Math.floor(Math.log(memorySize) / Math.log(1024));
    return (memorySize / Math.pow(1024, i));
  }

  private getMemoryUnit(memorySize: number): string {
    const i: number = memorySize == 0 ? 0 : Math.floor(Math.log(memorySize) / Math.log(1024));
    return this.sizeNames[i];
  }
}
